import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { LinkExternal } from '@fragments';
import { Video } from '../.';
import {
  EpisodeCardContainer,
  DateText,
  TitleText,
  DescriptionText,
  ServiceLinkList,
  MetaDiv,
  ServiceLogo,
} from './EpisodeCard.styled';
import { getLogo } from '@helpers/methods/logo';

const serviceIconConfig = {
  mobile: 24,
  desktop: 32,
};

export const EpisodeCard = ({ episode }) => {
  return (
    <EpisodeCardContainer>
      <Video videoSrcURL={episode.coverLink} videoTitle={episode.title} />
      <MetaDiv>
        <DateText>{episode.date}</DateText>
        <ServiceLinkList>
          {episode.links.map((link) => {
            const LogoComponent = getLogo(link.logo.toLowerCase());
            return (
              <ServiceLogo key={uniqid()}>
                <LinkExternal to={link.key}>
                  <LogoComponent config={{ width: serviceIconConfig.desktop, widthMb: serviceIconConfig.mobile }} />
                </LinkExternal>
              </ServiceLogo>
            );
          })}
        </ServiceLinkList>
      </MetaDiv>
      <TitleText>{episode.title}</TitleText>
      <DescriptionText>{episode.description}</DescriptionText>
    </EpisodeCardContainer>
  );
};

EpisodeCard.propTypes = {
  episode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    coverLink: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
