import React from 'react';
import { SectionEpisodesContainer, SectionTitle, ContainerGrid } from './SectionEpisodes.styled';
import dataFoundingImpact from '../FoundingImpact.data';
import { EpisodeCard } from '../components';

const {
  sectionEpisodes: { title, episodes },
} = dataFoundingImpact;

export const SectionEpisodes = () => {
  return (
    <SectionEpisodesContainer>
      <SectionTitle>{title}</SectionTitle>
      <ContainerGrid>
        {episodes.map((episode) => (
          <EpisodeCard key={episode.id} episode={episode} />
        ))}
      </ContainerGrid>
    </SectionEpisodesContainer>
  );
};
