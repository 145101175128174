import React from 'react';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionHeader, SectionEpisodes } from './sections';

const FoundingImpact = () => (
  <>
    <SEO title="Founding Impact | Ragnarson" description="Founding Impact Podcast" />
    <SectionHeader />
    <SectionEpisodes />
    <ContactForm headline={useStateScreenMobile() ? '' : <ContactFormHeader>Contact us</ContactFormHeader>} />
  </>
);

export default FoundingImpact;
