import React from 'react';
import { VikingImpact } from '@illustrations';

const dataFoundingImpact = {
  sectionHeader: {
    headline: <VikingImpact id="viking-impact" config={{ width: 578, widthMb: 300 }} />,
    content: `Founding impact in Europe is a podcast dedicated to startup founders and investors.
              We help them to better understand the latest developments in the ecosystem by interviewing various stakeholders.
    `,
    linkArray: [
      {
        to: '/fund',
        label: 'Ragnarson Fund',
        title: 'Go to Ragnarson Fund',
      },
      {
        to: '/manifesto',
        label: 'Manifesto',
      },
    ],
  },
  sectionEpisodes: {
    title: 'Recent Episodes',
    episodes: [
      {
        id: '12',
        date: '15 July, 2021',
        title: `Founding Impact: Is impact in services even possible?`,
        description: `There are two ways we do business at Ragnarson. On the one hand, we invest in impact-driven startups, on the other, we act as an agency with technical expertise,
          helping other businesses to build different kinds of products, like software as service solutions and online marketplaces. 
          While it’s relatively easy to focus on impact-driven startups on the investor’s side of the business, is it possible to do that when it comes to the agency?`,
        coverLink: 'https://www.youtube.com/embed/M2RIOQADiWA',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=M2RIOQADiWA',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/1SWu1LAODdORLwrZ7U0CPd?si=87279a95c34d4087',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/founding-impact-is-impact-in-services-even-possible/id1569205186?i=1000528970107',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUQJ7kNk',
            logo: 'ServiceOverCast',
          },
        ],
      },
      {
        id: '11',
        date: '24 June, 2021',
        title: `Founding impact: Why startup founders can’t ignore their mental wellbeing?`,
        description: `The majority of startup failures are caused by human-related elements and around 50% of founders may struggle with the challenges of mental health.
          In this episode we talked with Joshua Haynes from Masawa about the importance of mental wellness in startups and discussed how investors can support founders and build awareness of mental well being.`,
        coverLink: 'https://www.youtube.com/embed/_4MIDDox7hs',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=_4MIDDox7hs',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/2ec9qzm8R5vfSlynypkw2V?si=c56e6402f9fd4cfe',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/why-founders-cant-ignore-their-mental-well-being/id1569205186?i=1000526723004',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUR0YOHE',
            logo: 'ServiceOverCast',
          },
        ],
      },
      {
        id: '10',
        date: '10 June, 2021',
        title: `Founding Impact: How hard is it to reduce companies' emissions?`,
        description: `The number of impact-focused ventures in the European market is growing rapidly.
          It’s an indicator of growing awareness of global problems. One of them is excessive carbon emission. And that was exactly the problem we discussed in this episode together with our guest - Tobias Matetschlaeger from @Global Changer. `,
        coverLink: 'https://www.youtube.com/embed/fj9AKDNfTWY',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=fj9AKDNfTWY',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/5FZHM07LU4T4DWfPL7vXg6?si=d4c74a4ff7ef4154',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/how-hard-is-it-to-reduce-companies-emissions/id1569205186?i=1000524936171',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUQZIbKc',
            logo: 'ServiceOverCast',
          },
        ],
      },
      {
        id: '9',
        date: '20 May, 2021',
        title: `Founding Impact: It's time for women to invest!`,
        description: `“We can’t build a world that values women equally if women aren’t a bigger part of the building.”. This time, our guest is Terezia Jacova - co-founder of Lumus Investment,
          an organization that educates, supports, and connects women to investment opportunities, operating in the CEE region. `,
        coverLink: 'https://www.youtube.com/embed/JmfQnuRIhUM',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=JmfQnuRIhUM',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/3QFzxM8SQoOmIVNSANDJ3Y?si=868c4be486e44450',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/its-time-for-women-to-invest-guest-terezia-jacova-from/id1569205186?i=1000523055527',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUSsvvsE',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/founding-impact-its-time-for-women-to-invest',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '8',
        date: '24 Mar, 2021',
        title: 'Founding impact: Does Estonia have what it takes to become an impact frontrunner?',
        description: `Together with our guest, Erki Ani from Cleantech ForEst, we discussed all the important aspects of the Estonian startup ecosystem
          and talked about the possibility of the next impact unicorn coming from Estonia. `,
        coverLink: 'https://www.youtube.com/embed/cU3lTYc-v5I',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=cU3lTYc-v5I',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/7lzUxr4Nw3QStwo68Pes1z?si=185822717ce744dc ',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/does-estonia-have-what-it-takes-to-become-impact-frontrunner/id1569205186?i=1000523055528',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEURUrcVc',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/does-estonia-have-what-it-takes-to-become-an-impact-frontrunner',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '7',
        date: '24 Feb, 2021',
        title: 'Impact investing: exploring the impact startup scene in Romania',
        description: `Kasia talks about the growing Romanian startup ecosystem together with Vlad from Rubik Hub.
          A few years back, Romania wasn’t a place that investors considered worth checking when looking for impact-driven startups to invest in.
          But this has changed a lot. `,
        coverLink: 'https://www.youtube.com/embed/ALKRm-AE7ns',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=ALKRm-AE7ns',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/0p57u1rg7tmz6aqPUTTaH9?si=c099b073d0e64bdb',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/exploring-impact-startup-scene-in-romania-vlad-gliga/id1569205186?i=1000523055558',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUQ5R2lA',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/exploring-the-impact-startup-scene-in-romania',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '6',
        date: '9 Feb, 2021',
        title: 'Impact investing: water as a foundation of our economy with Nicola Lei Ravello',
        description: `Maciej together with Nicola Lei Ravello from White Stag Investing discuss the impact of the global water problem on the entire world, discussing the new investment possibilities in this area. `,
        coverLink: 'https://www.youtube.com/embed/pESX_jIALs0',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=pESX_jIALs0',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/4dQcCSpgHDhlWCN7T7oMNc?si=be360a068d5a48f3',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/water-as-foundation-our-economy-nicola-lei-ravello/id1569205186?i=1000523055517',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUQH0GbQ',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/water-as-the-foundation-of-our-economy',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '5',
        date: '20 Jan, 2021',
        title: 'Impact fundraising: public money? Sounds easy with Jonas Schorr from Urban Impact',
        description: `Kasia together with Jonas Schorr from Urban Impact discuss the whole new world of possibilities to get the public money for your business.
        There are plenty of options to get support from the public sector, but many founders are not aware of them. `,
        coverLink: 'https://www.youtube.com/embed/UXN5QES0lbA',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=UXN5QES0lbA',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/2VBz8VzOaVs5pxTUgyBCQ4?si=618c8b9c9d21401b',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/public-money-sounds-easy-jonas-schorr-from-urban-impact/id1569205186?i=1000523055557',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUTh185c',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/public-money-sounds-easy-with-jonas-schorr',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '4',
        date: '7 Jan, 2021',
        title: 'Impact investing: mistakes to avoid in your pitch deck',
        description: `On average, investors review dozens, hundreds, or even thousands of pitch decks through a year.
        During the review, they want to find out what the business is all about, what is the target group, key stakeholders, vision, and much more.
        That’s why a pitch deck needs to make a good first impression and contain all the important information.`,
        coverLink: 'https://www.youtube.com/embed/LWmBceXCnTE',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=LWmBceXCnTE',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/5cByUSEsYP9Uoc8op4S1Wx?si=f6ff86ea14614062',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/mistakes-to-avoid-in-your-pitch-deck/id1569205186?i=1000523055462',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUTfc9kA',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/mistakes-to-avoid-in-your-pitch-deck',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '3',
        date: '16 Dec, 2020',
        title: 'Impact investing: the relationship between founders',
        description: `When making an investment decision, investors consider many factors. One of them is the team - they expect it to be solid,
          hands-on, and motivated to deliver what the startup promises. `,
        coverLink: 'https://www.youtube.com/embed/w0bft9bNrg0',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=w0bft9bNrg0',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/59PwPMzrPlsideggvG8gRX?si=26a62f74c7ea40a4',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/the-relationship-between-founders/id1569205186?i=1000523055368 ',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUShtKs8',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/the-relationship-between-founders',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '2',
        date: '2 Dec, 2020',
        title: 'Impact investing: how to ask for intros to investors?',
        description: `In this video, we share some insights on asking for a referral from the investor’s point of view.`,
        coverLink: 'https://www.youtube.com/embed/Wzmwwi51XN4',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=Wzmwwi51XN4',
            logo: 'ServiceYoutube',
          },
          {
            key: 'https://open.spotify.com/episode/4oUVcwjpca8QBQr9RPBYAI?si=a4f3f59392884051',
            logo: 'ServiceSpotify',
          },
          {
            key: 'https://podcasts.apple.com/pl/podcast/how-to-ask-for-intros-to-investors/id1569205186?i=1000523055479',
            logo: 'ServicePodcast',
          },
          {
            key: 'https://overcast.fm/+vEUQN0hkk',
            logo: 'ServiceOverCast',
          },
          {
            key: 'https://soundcloud.com/foundingimpact/how-to-ask-for-intros-to-investors',
            logo: 'ServiceSoundcloud',
          },
        ],
      },
      {
        id: '1',
        date: '18 Nov, 2020',
        title: 'Impact investing: money vs purpose',
        description: `Many startups tend to focus only on the financial aspect and forget about the importance of impact and vice versa,
          while the motivation behind building the startup is what impact investors are very interested in.`,
        coverLink: 'https://www.youtube.com/embed/RoYjXkKpci0',
        links: [
          {
            key: 'https://www.youtube.com/watch?v=RoYjXkKpci0',
            logo: 'ServiceYoutube',
          },
        ],
      },
    ],
  },
};

export default dataFoundingImpact;
