import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { screenMax, spaceDt, spaceMb } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const CustomIframe = styled.iframe`
  width: 100%;
  height: ${toVW(320, 'desktop')};
  margin-bottom: ${spaceDt(2)};

  ${screenMax('lg')} {
    height: ${toVW(200, 'mobile')};
    margin-bottom: ${spaceMb(2)};
  }
`;

export const Video = ({ videoSrcURL, videoTitle }) => (
  <CustomIframe
    src={videoSrcURL}
    title={videoTitle}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
  />
);

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
};
