import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { spaceMb, spaceDt, getTypography, screenMax } from '@helpers/styles';

export const EpisodeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: ${spaceDt(3.5)} ${spaceDt(2)};

  ${screenMax('lg')} {
    width: 100%;
    padding: ${spaceMb(2)};
  }
`;

export const MetaDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spaceDt(1)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(1)};
  }
`;

export const DateText = styled.div`
  margin-right: ${spaceDt(1)};

  ${screenMax('lg')} {
    margin-right: ${spaceMb(1)};
  }

  ${getTypography('body-2')};
`;

export const TitleText = styled.div`
  margin-bottom: ${spaceDt(2.5)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(1.5)};
  }

  ${getTypography('heading-3')};
`;

export const DescriptionText = styled.div`
  margin-bottom: ${spaceDt(2.5)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(1.5)};
  }

  ${getTypography('body-2')};
`;

export const ServiceLinkList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${toVW(50, 'desktop')};

  ${screenMax('lg')} {
    mobile: ${toVW(50, 'mobile')};
  }
`;

export const ServiceLogo = styled.div`
  margin-left: ${spaceDt(0.5)};
  margin-right: ${spaceDt(0.5)};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(0.5)};
    margin-right: ${spaceMb(0.5)};
  }
`;
