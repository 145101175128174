import styled from 'styled-components';
import { Container } from '@fragments';
import { getTypography, spaceDt, spaceMb, screenMax } from '@helpers/styles';

const SectionEpisodesContainer = styled(Container)`
  margin-bottom: ${spaceDt(10)};

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(10)};
  }
`;

const SectionTitle = styled.div`
  ${getTypography('heading-2')};
`;

const ContainerGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export { SectionEpisodesContainer, SectionTitle, ContainerGrid };
